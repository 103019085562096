import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Video = makeShortcode("Video");
const Caption = makeShortcode("Caption");
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This is a demo page to see variations on components before/after headings.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Headers and Paragraphs</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Videos</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Images</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers & Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Accordions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Tables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Tabs</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Code</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Video</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Image</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Accordion</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Tables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Tabs</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Code</AnchorLink>
    </AnchorLinks>
    <h2>{`Headers and Paragraphs`}</h2>
    <h2>{`Header 2`}</h2>
    <p>{`I’m a paragraph underneath an h2. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`}</p>
    <h3>{`Header 3`}</h3>
    <p>{`I’m a paragraph underneath an h3. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`}</p>
    <h4>{`Header 4`}</h4>
    <p>{`I’m a paragraph underneath an h4. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`}</p>
    <h2>{`Headers and Videos`}</h2>
    <h2>{`Header 2`}</h2>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h3>{`Header 3`}</h3>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h4>{`Header 4`}</h4>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h2>{`Headers and Images`}</h2>
    <h2>{`Header 2`}</h2>
    <img {...{
      "src": "/images/poster.png",
      "alt": null
    }}></img>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <h3>{`Header 3`}</h3>
    <img {...{
      "src": "/images/poster.png",
      "alt": null
    }}></img>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <h4>{`Header 4`}</h4>
    <img {...{
      "src": "/images/poster.png",
      "alt": null
    }}></img>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <h2>{`Headers & Lists`}</h2>
    <h2>{`Header 2`}</h2>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}<ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol></li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}<ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul></li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h3>{`Header 3`}</h3>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}<ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol></li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}<ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul></li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h4>{`Header 4`}</h4>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}<ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol></li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}<ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul></li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h2>{`Headers and Accordions`}</h2>
    <h2>{`Header 2`}</h2>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h3>{`Header 3`}</h3>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h4>{`Header 4`}</h4>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h2>{`Headers and Tables`}</h2>
    <h2>{`Header 2`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Header 3`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Header 4`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Headers and Tabs`}</h2>
    <h2>{`Header 2`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <img {...{
          "src": "/images/poster.png",
          "alt": null
        }}></img>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h3>{`Header 3`}</h3>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <img {...{
          "src": "/images/poster.png",
          "alt": null
        }}></img>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h4>{`Header 4`}</h4>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <img {...{
          "src": "/images/poster.png",
          "alt": null
        }}></img>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h2>{`Headers and Code`}</h2>
    <h2>{`Header 2`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h3>{`Header 3`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h4>{`Header 4`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h2>{`Paragraph and Video`}</h2>
    <p>{`I’m a paragraph above a video. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <Video src="/videos/hero-video.mp4" poster="/images/poster.png" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <p>{`I’m a paragraph below a video. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Image`}</h2>
    <p>{`I’m a paragraph above an image. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <img {...{
      "src": "/images/poster.png",
      "alt": null
    }}></img>
    <p>{`I’m a paragraph below an image. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and List`}</h2>
    <p>{`I’m a paragraph above a list. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}<ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol></li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}<ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul></li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <p>{`I’m a paragraph below a list. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Accordion`}</h2>
    <p>{`I’m a paragraph above an Accordion. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <p>{`I’m a paragraph below an Accordion. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Tables`}</h2>
    <p>{`I’m a paragraph above a table. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`I’m a paragraph below a table. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Tabs`}</h2>
    <p>{`I’m a paragraph above Tabs. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <img {...{
          "src": "/images/poster.png",
          "alt": null
        }}></img>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <img {...{
              "src": "/images/poster.png",
              "alt": null
            }}></img>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <p>{`I’m a paragraph below Tabs. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Code`}</h2>
    <p>{`I’m a paragraph above code. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`<Tab label="Two images">

<Row>
<Column colSm={6} colLg={6}>

Column one

![](/images/poster.png)

</Column>
<Column colSm={6} colLg={6}>

Column two

![](/images/poster.png)

</Column>
</Row>

</Tab>

// Make sure you close the wrapper component
</Tabs>
`}</code></pre>
    <p>{`I’m a paragraph below code. Lorem ipsum dolor sit amet, consectetur adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      